//* eslint-disable react/prefer-stateless-function */
// React
import { Component, Suspense, lazy } from "react";
// Router
import { Switch, withRouter } from "react-router-dom";
// Semantic Ui
import { Loader } from "semantic-ui-react";
// Redux
import { connect } from "react-redux";
// Query string
import qs from "qs";
// Action
import { setModal, isMenuOpen } from "../../actions/general";
// History
import history from "../../history";
// Routes
import { PrivateRoute, PublicRoute } from "../../routers";
// Layouts
import {
  CommonLayout,
  NavLayout,
  WebsiteLayout,
} from "../../components/shared/layout";
import SelectClaimType from "../../components/dashboard/claimSubmission/SelectClaimType";

// Components load with lazy
const AddReceiptDetails = lazy(() =>
  import("../../components/dashboard/claimSubmission/AddReceiptDetails")
);
const UploadReceipt = lazy(() =>
  import("../../components/dashboard/claimSubmission/UploadReceipt")
);
const SetVerification = lazy(() =>
  import("../../components/auth/setVerification/SetVerification")
);
const SetPassword = lazy(() =>
  import("../../components/auth/setPassowrd/index")
);
const ClaimSubmission = lazy(() =>
  import("../../components/dashboard/claimSubmission/ClaimSubmission")
);
const ChangePassword = lazy(() =>
  import("../../components/auth/changePassword/index")
);
const Login = lazy(() => import("../../components/auth/login/index"));
const Logout = lazy(() => import("../../components/auth/logout/index"));
const RenewVerificationCode = lazy(() =>
  import("../../components/auth/renewVerificationCode/index")
);
const ResetPassword = lazy(() =>
  import("../../components/auth/resetPassword/index")
);
const SelfEnrolment = lazy(() =>
  import("../../components/dashboard/selfEnrolment")
);
const SelfEnroledCompanies = lazy(() =>
  import("../../components/dashboard/selfEnroledCompanies")
);
const SetNewPassword = lazy(() =>
  import("../../components/auth/setNewPassword/index")
);
const Signup = lazy(() => import("../../components/auth/signup/index"));
const AvailableRoles = lazy(() => import("../../components/auth/roles/index"));
const AddBHSelect = lazy(() =>
  import("../../components/dashboard/brokerageHouses/views/AddBHSelect")
);
const BHFull = lazy(() =>
  import("../../components/dashboard/brokerageHouses/views/BHFull/index")
);
const BHQuick = lazy(() =>
  import("../../components/dashboard/brokerageHouses/views/BHQuick/index")
);
const OnboardBrokerageHouse = lazy(() =>
  import("../../components/dashboard/brokerageHouses/views/BHOnboarding/index")
);
const AddBrokers = lazy(() =>
  import("../../components/dashboard/brokers/addBrokers")
);
const OnboardBroker = lazy(() =>
  import("../../components/dashboard/brokers/OnboardBroker")
);
const AddCompany = lazy(() =>
  import("../../components/dashboard/companies/AddCompany")
);
const CompanyDetails = lazy(() =>
  import("../../components/dashboard/companies/CompanyDetails/index")
);
const CompanyFullEnrolment = lazy(() =>
  import("../../components/dashboard/companies/CompanyFullEnrolment/index")
);
const OnboardCompany = lazy(() =>
  import("../../components/dashboard/companies/CompanyOnboarding/index")
);
const CompanyQuickEnrolment = lazy(() =>
  import("../../components/dashboard/companies/CompanyQuickEnrolment/index")
);
const AddDependent = lazy(() =>
  import("../../components/dashboard/dependents/addDependent")
);
const OnboardDependent = lazy(() =>
  import("../../components/dashboard/dependents/OnboardDependent")
);
const AddEmployee = lazy(() =>
  import("../../components/dashboard/employees/addEmployee")
);
const EmployeeFullEnrolment = lazy(() =>
  import("../../components/dashboard/employees/EmployeeFullEnrolment/index")
);
const OnboardEmployee = lazy(() =>
  import("../../components/dashboard/employees/EmployeeOnboarding/index")
);
const EmployeeQuickEnrolment = lazy(() =>
  import("../../components/dashboard/employees/EmployeeQuickEnrolment/index")
);
const AuditInvoice = lazy(() =>
  import("../../components/dashboard/invoice/AuditInvoice/index")
);
const RecordPayment = lazy(() =>
  import("../../components/dashboard/payments/RecordPayment/index")
);
const AddTPA = lazy(() => import("../../components/dashboard/tpa/addTPA"));
const AddTPAFull = lazy(() =>
  import("../../components/dashboard/tpa/fullEnrolment")
);
const OnboardTPA = lazy(() =>
  import("../../components/dashboard/tpa/TpaReviewfullEnrolment")
);
const AddTpaSR = lazy(() =>
  import("../../components/dashboard/tpaSR/AddTpaSR")
);
const OnboardTpaSr = lazy(() =>
  import("../../components/dashboard/tpaSR/OnboardTpaSr")
);
const Advisors = lazy(() => import("../auxiliaryPages/Advisors"));
const Employees = lazy(() => import("../auxiliaryPages/Employees"));
const Employers = lazy(() => import("../auxiliaryPages/Employers"));
const Dashboard = lazy(() => import("../dashboard"));
const TermsModalPage = lazy(() => import("../TermsModalPage"));
const CollectionLedger = lazy(() =>
  import("../../components/dashboard/ledger/LedgerAccounts/CollectionLedger")
);
const OperationsLedger = lazy(() =>
  import("../../components/dashboard/ledger/LedgerAccounts/OperationsLedger")
);
const PrefundLedger = lazy(() =>
  import("../../components/dashboard/ledger/LedgerAccounts/PrefundLedger")
);
const SettlementLedger = lazy(() =>
  import("../../components/dashboard/ledger/LedgerAccounts/SettlementLedger")
);
const ReimbursementLedger = lazy(() =>
  import("../../components/dashboard/ledger/LedgerAccounts/ReimbursementLedger")
);
const ReturnLedger = lazy(() =>
  import("../../components/dashboard/ledger/LedgerAccounts/ReturnLedger")
);
const IssuerFeeLedger = lazy(() =>
  import("../../components/dashboard/ledger/LedgerAccounts/IssuerFeeLedger")
);
const MastercardLedger = lazy(() =>
  import("../../components/dashboard/ledger/LedgerAccounts/MastercardLedger")
);
const ProcessorReport = lazy(() =>
  import("../../components/dashboard/processor/Views/ProcessorReport")
);
const SetNewEmail = lazy(() => import("../../components/auth/setNewEmail"));
const CardActivation = lazy(() =>
  import("../../components/dashboard/cardActivation/CardActivation")
);
const Sls = lazy(() => import("../../components/dashboard/sls/Sls"));
const ClaimActivity = lazy(() =>
  import("../../components/dashboard/activity/ClaimActivity")
);

class RootScreen extends Component {
  previousLocation = this.props.location;
  allowRedirect = this.props.match.url !== this.props.location.pathname;

  componentDidMount() {
    if (window.innerWidth < 900) {
      this.props.isMenuOpen({ override: false });
    }

    let createModal = qs.parse(this.props.location?.search, {
      ignoreQueryPrefix: true,
    });

    if (createModal?.gmodal && createModal?.gref) {
      this.props.setModal({
        modal: { isOpen: true, searchQuery: this.props.location.search },
      });
    }
  }

  componentWillUpdate(nextProps) {
    let { location } = this.props;
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  render() {
    let { location } = this.props;
    const locationSearchQuery = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    let isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    ); // not initial render

    //This check helps to implement the policy need for accessing modal routes with a directly with a direct url.
    if (!isModal) {
      switch (location.pathname) {
        case "/reset-password":
          setTimeout(() => {
            history.push("/reset-password", { modal: true });
          }, 1000);
          history.push("/");
          break;
        case "/renew-verification-code":
          setTimeout(() => {
            history.push("/renew-verification-code", {
              modal: true,
              token: location.hash.substr(1),
            });
          }, 1000);
          history.push("/");
          break;
        default:
          break;
      }

      //switch cases for path parameters
      switch (locationSearchQuery.path) {
        case "reset-password":
          setTimeout(() => {
            this.props.history.push("/reset-password", { modal: true });
          }, 1000);
          this.props.history.push("/");
          break;
        case "set-new-password":
          this.props.history.push({
            pathname: "/reset-password",
            state: { modal: true, ...locationSearchQuery },
          });
          break;
        case "terms-of-service":
          setTimeout(() => {
            this.props.history.push("/terms-of-service", { modal: true });
          }, 1000);
          this.props.history.push("/");
          break;
        case "esign-agreement":
          setTimeout(() => {
            this.props.history.push("/esign-agreement", { modal: true });
          }, 1000);
          this.props.history.push("/");
          break;
        case "pre-authorized-debit":
          setTimeout(() => {
            this.props.history.push("/pre-authorized-debit", { modal: true });
          }, 1000);
          this.props.history.push("/");
          break;
        case "card-member-agreement":
          setTimeout(() => {
            this.props.history.push("/card-member-agreement", { modal: true });
          }, 1000);
          this.props.history.push("/");
          break;
        case "privacy-policy":
          setTimeout(() => {
            this.props.history.push("/privacy-policy", { modal: true });
          }, 1000);
          this.props.history.push("/");
          break;
        case "support":
          setTimeout(() => {
            this.props.history.push("/support", { modal: true });
          }, 1000);
          this.props.history.push("/");
          break;
        default:
          break;
      }
    }

    return (
      <div>
        <Suspense
          fallback={
            <NavLayout
              layoutProps={{
                logoToBaseUrl: true,
                rightMenuPane: false,
                shadow: false,
                history,
              }}
            >
              <Loader active />
            </NavLayout>
          }
        >
          <Switch location={isModal ? this.previousLocation : location}>
            <PublicRoute
              exact
              path="/"
              layout={NavLayout}
              layoutProps={{
                logoToBaseUrl: true,
                rightMenuPane: true,
                shadow: false,
                history,
              }}
            >
              {" "}
              <Login />{" "}
            </PublicRoute>
            <PublicRoute exact path="/support">
              {" "}
              <TermsModalPage section="TERMS_OF_SERVICE" />
            </PublicRoute>
            <PrivateRoute
              exact
              path="/role"
              layout={NavLayout}
              layoutProps={{
                logoToBaseUrl: true,
                rightMenuPane: true,
                shadow: false,
                history,
              }}
            >
              {" "}
              <AvailableRoles />{" "}
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/role/select"
              layout={NavLayout}
              layoutProps={{
                logoToBaseUrl: true,
                rightMenuPane: true,
                shadow: false,
                history,
              }}
            >
              {" "}
              <AvailableRoles />{" "}
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/dashboard"
              layout={null}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="Main" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/brokerage-houses"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="BrokerageHouses" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/brokers"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="Brokers" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/tpa"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="tpa" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/tpa-sr"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="tpaSR" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/companies"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="companies" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/self-enroled-companies"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="selfEnroledCompanies" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/employees"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="employees" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/dependents"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="dependents" />
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/reports"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="reports" />
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/claim-submission"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <ClaimSubmission />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/select-claim-type"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <SelectClaimType />
            </PrivateRoute>
            {/* <PrivateRoute
              exact
              path="/claim-submission/:component"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <ClaimSubmission />
            </PrivateRoute> */}
            <PrivateRoute
              exact
              path="/add-receipt"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <AddReceiptDetails />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/upload-receipt"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <UploadReceipt />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/payments"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="payments" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/settings"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="settings" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/plan-info"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="planInfo" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/company-plan"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="companyPlan" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/employeeInfo"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              <Dashboard component="employeeInfo" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/planAdminUserEnrolment"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              <Dashboard component="planAdminPcoEnrolment" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/planAdminInvoices"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              <Dashboard component="planAdminInvoices" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/planAdminReports"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              <Dashboard component="planAdminReports" />
            </PrivateRoute>

            <PrivateRoute
              exact
              path="/company-info"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              <Dashboard component="companyPlan" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/activity"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              <ClaimActivity />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/activate-card"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              <CardActivation />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/list-dependents"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              <Dashboard component="listDependents" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/hsa-coverage"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              <Dashboard component="hsaCoverage" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/hsa-adjudication"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="HsaAdjudication" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/intermediaries"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="Intermediaries" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/invoice"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="invoice" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/processor"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="processor" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/merchant"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="merchant" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/users"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="users" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/bank-info"
              layout={CommonLayout}
              layoutProps={{ leftMenuPane: false, shadow: true, history }}
            >
              {" "}
              <Dashboard component="bankInfo" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/brokerage-houses/add"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <AddBHSelect />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/brokerage-houses/add/quick"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              <BHQuick />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/brokerage-houses/add/full"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              <BHFull />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/tpa/add"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <AddTPA />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/brokerage-houses/onboarding"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <OnboardBrokerageHouse />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/tpa/onboarding"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <OnboardTPA />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/tpa-sr/onboarding"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <OnboardTpaSr />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/broker/onboarding"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <OnboardBroker />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/company/onboarding"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <OnboardCompany />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/employee/onboarding"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: false,
                transparent: false,
              }}
            >
              {" "}
              <OnboardEmployee />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/dependent/onboarding"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <OnboardDependent />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/tpa/add/full"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <AddTPAFull />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/brokers/add"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <AddBrokers />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/tpa-sr/add"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <AddTpaSR />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/companies/add"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <AddCompany />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/companies/add/quick"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <CompanyQuickEnrolment />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/companies/add/full"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <CompanyFullEnrolment />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/employees/add"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <AddEmployee />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/employees/add/quick"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <EmployeeQuickEnrolment />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/employees/add/full"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <EmployeeFullEnrolment />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/dependents/add"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <AddDependent />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/payments/record"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              {" "}
              <RecordPayment />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/invoice/audit"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <AuditInvoice />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/ledger"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <Dashboard component="ledger" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/brokerage-house-admin"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
              }}
            >
              {" "}
              <Dashboard component="bhAdmin" />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/ledger/collection"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <CollectionLedger />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/ledger/operating"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <OperationsLedger />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/ledger/prefund"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <PrefundLedger />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/ledger/settlement"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <SettlementLedger />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/ledger/reimbursement"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <ReimbursementLedger />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/ledger/return"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <ReturnLedger />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/ledger/issuerfee"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <IssuerFeeLedger />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/ledger/mastercard"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <MastercardLedger />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/processor/report"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <ProcessorReport />
            </PrivateRoute>
            <PrivateRoute
              exact
              path="/sls"
              layout={CommonLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: true,
                transparent: false,
                userRole: "finance",
              }}
            >
              <Sls />
            </PrivateRoute>

            <PublicRoute
              exact
              path="/set-verification"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: false,
                transparent: false,
              }}
            >
              <SetVerification />
            </PublicRoute>

            <PublicRoute
              exact
              path="/set-password"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: false,
                transparent: false,
              }}
            >
              <SetPassword />
            </PublicRoute>
            <PublicRoute
              exact
              path="/self-enrolment"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: false,
                transparent: false,
              }}
            >
              <SelfEnrolment />
            </PublicRoute>

            <PublicRoute
              exact
              path="/self-enrolment/custom"
              layout={NavLayout}
              layoutProps={{
                leftMenuPane: false,
                shadow: false,
                transparent: false,
              }}
            >
              <SelfEnrolment />
            </PublicRoute>

            <PublicRoute
              exact
              path="/a/advisors"
              layout={WebsiteLayout}
              layoutProps={{ rightMenuPane: true, shadow: true, history }}
            >
              {" "}
              <Advisors />
            </PublicRoute>
            <PublicRoute
              exact
              path="/a/employers"
              layout={WebsiteLayout}
              layoutProps={{ rightMenuPane: true, shadow: true, history }}
            >
              {" "}
              <Employers />
            </PublicRoute>
            <PublicRoute
              exact
              path="/a/employees"
              layout={WebsiteLayout}
              layoutProps={{ rightMenuPane: true, shadow: true, history }}
            >
              {" "}
              <Employees />
            </PublicRoute>
            <PublicRoute exact path="/terms-of-service">
              {" "}
              <TermsModalPage section="TERMS_OF_SERVICE" />
            </PublicRoute>
            <PublicRoute exact path="/privacy-policy">
              {" "}
              <TermsModalPage section="PRIVACY_POLICY" />
            </PublicRoute>
            <PublicRoute exact path="/esign-agreement">
              {" "}
              <TermsModalPage section="ESIGN_AGREEMENT" />
            </PublicRoute>
            <PublicRoute exact path="/pre-authorized-debit">
              {" "}
              <TermsModalPage section="PAD" />
            </PublicRoute>
            <PublicRoute exact path="/card-member-agreement">
              {" "}
              <TermsModalPage section="CARD_MEMBER_AGREEMENT" />
            </PublicRoute>
            <PublicRoute exact path="/set-new-password">
              {" "}
              <SetNewPassword />
            </PublicRoute>
            <PublicRoute
              path="*"
              layout={NavLayout}
              layoutProps={{
                logoToBaseUrl: true,
                rightMenuPane: true,
                shadow: false,
                history,
              }}
            >
              {" "}
              <Login />
            </PublicRoute>
          </Switch>
          {isModal && (
            <PublicRoute
              exact
              path="/login"
              layout={NavLayout}
              layoutProps={{
                logoToBaseUrl: true,
                rightMenuPane: true,
                shadow: false,
                history,
              }}
            >
              {" "}
              <Login />
            </PublicRoute>
          )}
          {isModal && (
            <PublicRoute
              exact
              path="/signup"
              layout={NavLayout}
              layoutProps={{ rightMenuPane: true, shadow: true, history }}
            >
              {" "}
              <Signup />
            </PublicRoute>
          )}
          {isModal && (
            <PublicRoute exact path="/change-password">
              <ChangePassword />{" "}
            </PublicRoute>
          )}
          {isModal && (
            <PublicRoute exact path="/reset-password">
              <ResetPassword />
            </PublicRoute>
          )}
          {isModal && (
            <PublicRoute exact path="/logout">
              {" "}
              <Logout />
            </PublicRoute>
          )}
          {isModal && (
            <PrivateRoute exact path="/company">
              {" "}
              <CompanyDetails />
            </PrivateRoute>
          )}
          {isModal && (
            <PublicRoute exact path="/terms-of-service">
              {" "}
              <TermsModalPage section="TERMS_OF_SERVICE" />
            </PublicRoute>
          )}
          {isModal && (
            <PublicRoute exact path="/pre-authorized-debit">
              {" "}
              <TermsModalPage section="PAD" />
            </PublicRoute>
          )}
          {isModal && (
            <PublicRoute exact path="/privacy-policy">
              {" "}
              <TermsModalPage section="PRIVACY_POLICY" />
            </PublicRoute>
          )}
          {isModal && (
            <PublicRoute exact path="/esign-agreement">
              {" "}
              <TermsModalPage section="ESIGN_AGREEMENT" />
            </PublicRoute>
          )}
          {isModal && (
            <PublicRoute exact path="/card-member-agreement">
              {" "}
              <TermsModalPage section="CARD_MEMBER_AGREEMENT" />
            </PublicRoute>
          )}
          {isModal && (
            <PublicRoute exact path="/renew-verification-code">
              {" "}
              <RenewVerificationCode token={location.state.token} />
            </PublicRoute>
          )}
        </Suspense>
      </div>
    );
  }
}

const ayaVersion = 20;

export default connect(null, { setModal, isMenuOpen })(withRouter(RootScreen));
